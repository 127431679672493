import { defineRule } from 'vee-validate'
import { BigNumberInBase } from '@injectivelabs/utils'
import { INJ_REQUIRED_FOR_GAS } from '@shared/utils/constant'
import {
  isBlockFormat,
  isAddressFormat,
  isSubaccountFormat,
  isTransactionFormat,
  isValidInjDomainName,
  isEthereumAddressFormat,
  isValidInjBonfidaDomainName
} from '@/app/utils/helper'
import { subaccountToInj } from '@/app/utils/formatter'
import {
  VALID_GLOBAL_SEARCH_FORMAT,
  VALID_TRADE_HISTORY_SEARCH_FORMAT
} from '@/app/utils/constant'

const errorMessages = {
  account: () => "Your search doesn't match an account address format",
  subaccount: () => "Your search doesn't match a subaccount address format",
  block: () => "Your search doesn't match a block height format",
  blockHash: () => "Your search doesn't match a block hash format",
  bridged: () => "Your search doesn't match a bridged ERC20 asset format",
  ibc: () => "Your search doesn't match an IBC asset format",
  native: () =>
    "Your search doesn't match a factory, insurance fund, or native inj asset format",
  transaction: () => "Your search doesn't match a transaction format",
  injName: () => 'This field is not a valid Injective name',
  injBonfidaName: () => 'This field is not a valid Injective name',
  validGlobalSearchFormat: () =>
    "Your search doesn't match a block, transaction, asset, or address",
  validTradeHistorySearchFormat: () =>
    "Your search doesn't match an injective address or subaccountId",
  subaccountId: () => "Your search doesn't match a subaccountId format",
  maxBalance: () =>
    'Amount exceeds available balance. Please enter a valid amount.',
  maxBalanceWithGasBuffer: () =>
    `Insufficient balance. Gas fees are ${INJ_REQUIRED_FOR_GAS} INJ`
} as Record<string, any>

export const defineGlobalRules = () => {
  defineRule('etherAccount', (value: string) => {
    if (!isEthereumAddressFormat(value)) {
      return errorMessages.account()
    }

    return true
  })

  defineRule('account', (value: string) => {
    if (!isAddressFormat(value)) {
      return errorMessages.account()
    }

    return true
  })

  defineRule('subaccount', (value: string) => {
    if (!isSubaccountFormat(value)) {
      return errorMessages.subaccount()
    }

    return true
  })

  defineRule('block', (value: string) => {
    if (!isBlockFormat(value)) {
      return errorMessages.block()
    }

    return true
  })

  defineRule('blockHash', (value: string) => {
    if (!isTransactionFormat(value)) {
      return errorMessages.blockHash()
    }

    return true
  })

  defineRule('transaction', (value: string) => {
    if (!isTransactionFormat(value)) {
      return errorMessages.transaction()
    }

    return true
  })

  defineRule('subaccountId', (value: string) => {
    if (!subaccountToInj(value)) {
      return errorMessages.subaccountId()
    }

    return true
  })

  defineRule(VALID_TRADE_HISTORY_SEARCH_FORMAT, (value: string) => {
    if (!value) {
      return true
    }

    const condition =
      value.toLowerCase().startsWith('0x') ||
      value.toLowerCase().startsWith('inj')
    if (!condition) {
      return errorMessages.validTradeHistorySearchFormat()
    }

    return true
  })

  defineRule('injDomainName', (value: string) => {
    if (!value) {
      return true
    }

    if (!isValidInjDomainName(value)) {
      return errorMessages.injName(value)
    }

    return true
  })

  defineRule('injBonfidaDomainName', (value: string) => {
    if (!value) {
      return true
    }

    if (!isValidInjBonfidaDomainName(value)) {
      return errorMessages.injBonfidaName(value)
    }

    return true
  })

  defineRule(VALID_GLOBAL_SEARCH_FORMAT, (value: string) => {
    if (!value) {
      return true
    }

    const condition =
      value.toLowerCase().startsWith('0x') ||
      value.toLowerCase().startsWith('inj') ||
      !Number.isNaN(Number(value)) ||
      // tx & block hash without prefix
      (value.length === 64 && !value.toLowerCase().startsWith('0x'))

    if (!condition) {
      return errorMessages.validGlobalSearchFormat()
    }

    return true
  })

  defineRule('validJson', (value: string) => {
    try {
      JSON.parse(value)

      return true
    } catch (e: any) {
      return e.message
    }
  })

  defineRule(
    'maxBalance',
    (value: string, [max, isBalanceWithGasBuffer]: [string, string]) => {
      if (!value) {
        return true
      }

      const valueIsGreaterThanMax = new BigNumberInBase(value).gt(max)

      if (isBalanceWithGasBuffer === 'true' && valueIsGreaterThanMax) {
        return errorMessages.maxBalanceWithGasBuffer()
      }

      if (valueIsGreaterThanMax) {
        return errorMessages.maxBalance()
      }

      return true
    }
  )
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
