import {
  AccountSubPage,
  I18nMessageFunction,
  UnstakingTableColumn,
  StakedTableColumn,
  AccountBalancesSubPage,
  Cw20BalancesTableColumn,
  RestakingTableColumn,
  AccountBalanceTableColumn
} from '@/types'

export default {
  account: {
    table: {
      staked: {
        [StakedTableColumn.Reward]: 'Reward',
        [StakedTableColumn.Amount]: 'Amount',
        [StakedTableColumn.Validator]: 'Validator'
      },
      restaking: {
        [RestakingTableColumn.Amount]: 'Amount',
        [RestakingTableColumn.DestinationValidator]: 'Destination Validator',
        [RestakingTableColumn.SourceValidator]: 'Source Validator',
        [RestakingTableColumn.CompletitionTime]: 'Completition Time'
      },
      unstaking: {
        [UnstakingTableColumn.Amount]: 'Amount',
        [UnstakingTableColumn.Validator]: 'Validator',
        [UnstakingTableColumn.CompletitionTime]: 'Completition Time'
      },
      balances: {
        [AccountBalanceTableColumn.Price]: 'Price',
        [AccountBalanceTableColumn.Amount]: 'Amount',
        [AccountBalanceTableColumn.Holding]: 'Holding',
        [AccountBalanceTableColumn.UsdValue]: 'USD Value',
        [AccountBalanceTableColumn.UnrealizedPnl]: 'Unrealized PnL',
        [AccountBalanceTableColumn.InUseReserved]: 'In Use/Reserved',
        [AccountBalanceTableColumn.AvailableBalance]: 'Available Balance'
      },
      cw20Balances: {
        [Cw20BalancesTableColumn.Balance]: 'Balance',
        [Cw20BalancesTableColumn.Holding]: 'Holding',
        [Cw20BalancesTableColumn.ContractAddress]: 'Contract Address'
      }
    },
    cw20: 'CW',
    ibc: 'IBC',
    bank: 'Bank',
    total: 'Total',
    value: 'Value',
    amount: 'Amount',
    native: 'Native',
    price: 'Price',
    staked: 'Staked',
    tokens: 'Tokens',
    reward: 'Reward',
    address: 'Address',
    balance: 'Balance',
    holding: 'Holding',
    unknown: 'Unknown',
    unstake: 'Unstake',
    restake: 'Restake',
    balances: 'Balances',
    cosmwasm: 'CosmWasm',
    holdings: 'Holdings',
    usdValue: 'USD Value',
    validator: 'Validator',
    allAssets: 'All Assets',
    assetType: 'Asset Type',
    unbondings: 'Unbondings',
    injBalance: 'INJ Balance',
    totalValue: 'Total value',
    details: 'Address details',
    delegations: 'Delegations',
    bridgedERC20: 'Bridged ERC20',
    cw20Balances: 'CW20 Balances',
    pathToOrigin: 'Path to origin',
    insuranceFund: 'Insurance Fund',
    unrealizedPnl: 'Unrealized PnL',
    inUseReserved: 'In Use/Reserved',
    tradingAccount: 'Trading Account',
    unknownNetwork: 'Unknown Network',
    contractAddress: 'Contract Address',
    sourceValidator: 'Source Validator',
    whatDoesItMean: 'What does it mean?',
    availableBalance: 'Available Balance',
    claimableRewards: 'Claimable Rewards',
    completitionTime: 'Completition Time',
    notFoundDescription: 'Please try again.',
    latestTransactions: 'Latest Transactions',
    noUnstakingFound: 'No assets unstaking.',
    insuranceFundToken: 'Insurance Fund Token',
    noStakedFound: 'No assets staked.',
    destinationValidator: 'Destination Validator',
    noTransactionsFound: 'No transactions found.',
    noRestakingFound: 'No assets restaking.',
    tradingAccountBalances: 'Trading Account Balances',
    isBalanceBreakdownEnabled: 'Include balance breakdown',
    invalidDescription: 'Please re-enter the account address correctly.',
    invalidTitle: 'Oops! The following account address format is incorrect:',
    notFoundTitle: ({ named }: I18nMessageFunction) =>
      `Sorry, We are unable to locate this account on ${named('network')}:`,
    ibcAssetPathDisplay:
      'The IBC assets have an unique way to display the origin and transfer paths of the assets.',
    amountIncludes:
      'Amount includes Margin and Unrealized PnL from open positions as well as open orders.',
    totalValueIncludes:
      'Total value includes Margin and Unrealized PnL from open positions as well as open orders and staked amount. Keep in mind that Positions and Unrealized PnL are loaded in the background.',
    verified: 'Verified',
    internalVerification: 'Internally Verified',
    externalVerification: 'Externally Verified',
    unverified: 'Unverified',
    tokenFactory: 'Token Factory',

    tabs: {
      [AccountSubPage.IndexPage]: 'Balances',
      [AccountSubPage.TransactionsPage]: 'Transactions',
      [AccountSubPage.StakingPage]: 'Staking',
      [AccountSubPage.NftPage]: 'NFTs'
    },
    balanceTabs: {
      [AccountBalancesSubPage.IndexPage]: 'Injective',
      [AccountBalancesSubPage.Cw20Page]: 'CW20'
    }
  }
}
