import { default as _404VP3H4oMKLaMeta } from "/root/injective/injective-explorer/pages/404.vue?macro=true";
import { default as cw20yaT3qXhRBtMeta } from "/root/injective/injective-explorer/pages/account/[account]/index/cw20.vue?macro=true";
import { default as indexlFPwxRwHmoMeta } from "/root/injective/injective-explorer/pages/account/[account]/index/index.vue?macro=true";
import { default as indexEtKApAjMUFMeta } from "/root/injective/injective-explorer/pages/account/[account]/index.vue?macro=true";
import { default as indexm7ewJQaqwKMeta } from "/root/injective/injective-explorer/pages/account/[account]/nft/index.vue?macro=true";
import { default as indexASn0H6GWapMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/index.vue?macro=true";
import { default as restakeFJBNWfvwyAMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/restake.vue?macro=true";
import { default as unstakevHnIIyd5PSMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/unstake.vue?macro=true";
import { default as stakingM7WPntzGg2Meta } from "/root/injective/injective-explorer/pages/account/[account]/staking.vue?macro=true";
import { default as transactionsC1GKJEVodgMeta } from "/root/injective/injective-explorer/pages/account/[account]/transactions.vue?macro=true";
import { default as _91account_93DGLYNxvW9qMeta } from "/root/injective/injective-explorer/pages/account/[account].vue?macro=true";
import { default as _91denom_93KOhnlVDyIeMeta } from "/root/injective/injective-explorer/pages/asset/[denom].vue?macro=true";
import { default as indexTmsuElRnfNMeta } from "/root/injective/injective-explorer/pages/assets/index.vue?macro=true";
import { default as smart_45contracts4VeFRZarQmMeta } from "/root/injective/injective-explorer/pages/assets/smart-contracts.vue?macro=true";
import { default as assetsHcZHB6i7BVMeta } from "/root/injective/injective-explorer/pages/assets.vue?macro=true";
import { default as _91block_93xUC4khrIXxMeta } from "/root/injective/injective-explorer/pages/block/[block].vue?macro=true";
import { default as blocksegUtDtgV3CMeta } from "/root/injective/injective-explorer/pages/blocks.vue?macro=true";
import { default as _91code_93Q2BY7vEWapMeta } from "/root/injective/injective-explorer/pages/code/[code].vue?macro=true";
import { default as community_45spend_45poolWGL8qqFK5xMeta } from "/root/injective/injective-explorer/pages/community-spend-pool.vue?macro=true";
import { default as balances7a9hXHtGjOMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/balances.vue?macro=true";
import { default as cw20balancesi6ePunGBeYMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/cw20balances.vue?macro=true";
import { default as indexZGpec3rzKQMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/index.vue?macro=true";
import { default as init8Xmve2jK5bMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/init.vue?macro=true";
import { default as executeo7DVPpJPb4Meta } from "/root/injective/injective-explorer/pages/contract/[contract]/query-and-execute/execute.vue?macro=true";
import { default as indexDyuNm2xylAMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/query-and-execute/index.vue?macro=true";
import { default as query_45and_45executefHFV55VTJUMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/query-and-execute.vue?macro=true";
import { default as token_45holdersFIsWfKzYFmMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/token-holders.vue?macro=true";
import { default as _91contract_93GlV318eHqYMeta } from "/root/injective/injective-explorer/pages/contract/[contract].vue?macro=true";
import { default as envfVxRgKM8eSMeta } from "/root/injective/injective-explorer/pages/env.vue?macro=true";
import { default as indexWZOyDGGrETMeta } from "/root/injective/injective-explorer/pages/index.vue?macro=true";
import { default as maintenancepyNbpbFYruMeta } from "/root/injective/injective-explorer/pages/maintenance.vue?macro=true";
import { default as _91market_93jSnTetkQy7Meta } from "/root/injective/injective-explorer/pages/market/[market].vue?macro=true";
import { default as funding_45rate_45historyTGshQH2inOMeta } from "/root/injective/injective-explorer/pages/markets/funding-rate-history.vue?macro=true";
import { default as indexmMfb5AoAc5Meta } from "/root/injective/injective-explorer/pages/markets/index.vue?macro=true";
import { default as realtime_45funding_45rateumdgJ3vpsXMeta } from "/root/injective/injective-explorer/pages/markets/realtime-funding-rate.vue?macro=true";
import { default as trading_45rulesX6fKsWlUhiMeta } from "/root/injective/injective-explorer/pages/markets/trading-rules.vue?macro=true";
import { default as marketsVfkNwOYM9KMeta } from "/root/injective/injective-explorer/pages/markets.vue?macro=true";
import { default as index6IvZV8DsNhMeta } from "/root/injective/injective-explorer/pages/nft/[id]/index.vue?macro=true";
import { default as raw_45txYYZOTj6lA5Meta } from "/root/injective/injective-explorer/pages/raw-tx.vue?macro=true";
import { default as codennh4Ep5meXMeta } from "/root/injective/injective-explorer/pages/smart-contracts/code.vue?macro=true";
import { default as indexEPjrpkTKRnMeta } from "/root/injective/injective-explorer/pages/smart-contracts/index.vue?macro=true";
import { default as smart_45contractsStjYabRro6Meta } from "/root/injective/injective-explorer/pages/smart-contracts.vue?macro=true";
import { default as testingv6cXzleTduMeta } from "/root/injective/injective-explorer/pages/testing.vue?macro=true";
import { default as _91tradeId_93PPpdtTkqjjMeta } from "/root/injective/injective-explorer/pages/trade-history/derivatives/[tradeId].vue?macro=true";
import { default as indexYJ8GcAEGLGMeta } from "/root/injective/injective-explorer/pages/trade-history/derivatives/index.vue?macro=true";
import { default as indexoVQXdDDGgUMeta } from "/root/injective/injective-explorer/pages/trade-history/index.vue?macro=true";
import { default as _91tradeId_93ovBqdHLNdVMeta } from "/root/injective/injective-explorer/pages/trade-history/spot/[tradeId].vue?macro=true";
import { default as indexPrDLL3R2lqMeta } from "/root/injective/injective-explorer/pages/trade-history/spot/index.vue?macro=true";
import { default as trade_45history4wdxWrri2GMeta } from "/root/injective/injective-explorer/pages/trade-history.vue?macro=true";
import { default as event_45logsDp3qPiJPVyMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction]/event-logs.vue?macro=true";
import { default as indexWJ8edPC8EWMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction]/index.vue?macro=true";
import { default as _91transaction_93O7jI16oisQMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction].vue?macro=true";
import { default as transactionsc0W8ckAwY1Meta } from "/root/injective/injective-explorer/pages/transactions.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/root/injective/injective-explorer/pages/404.vue")
  },
  {
    name: _91account_93DGLYNxvW9qMeta?.name,
    path: "/account/:account()",
    meta: _91account_93DGLYNxvW9qMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/account/[account].vue"),
    children: [
  {
    name: indexEtKApAjMUFMeta?.name,
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/index.vue"),
    children: [
  {
    name: "account-account-index-cw20",
    path: "cw20",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/index/cw20.vue")
  },
  {
    name: "account-account-index",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/index/index.vue")
  }
]
  },
  {
    name: "account-account-nft",
    path: "nft",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/nft/index.vue")
  },
  {
    name: stakingM7WPntzGg2Meta?.name,
    path: "staking",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking.vue"),
    children: [
  {
    name: "account-account-staking",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/index.vue")
  },
  {
    name: "account-account-staking-restake",
    path: "restake",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/restake.vue")
  },
  {
    name: "account-account-staking-unstake",
    path: "unstake",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/unstake.vue")
  }
]
  },
  {
    name: "account-account-transactions",
    path: "transactions",
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/transactions.vue")
  }
]
  },
  {
    name: "asset-denom",
    path: "/asset/:denom()",
    component: () => import("/root/injective/injective-explorer/pages/asset/[denom].vue")
  },
  {
    name: assetsHcZHB6i7BVMeta?.name,
    path: "/assets",
    component: () => import("/root/injective/injective-explorer/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/assets/index.vue")
  },
  {
    name: "assets-smart-contracts",
    path: "smart-contracts",
    component: () => import("/root/injective/injective-explorer/pages/assets/smart-contracts.vue")
  }
]
  },
  {
    name: "block-block",
    path: "/block/:block()",
    meta: _91block_93xUC4khrIXxMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/block/[block].vue")
  },
  {
    name: "blocks",
    path: "/blocks",
    component: () => import("/root/injective/injective-explorer/pages/blocks.vue")
  },
  {
    name: "code-code",
    path: "/code/:code()",
    meta: _91code_93Q2BY7vEWapMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/code/[code].vue")
  },
  {
    name: "community-spend-pool",
    path: "/community-spend-pool",
    component: () => import("/root/injective/injective-explorer/pages/community-spend-pool.vue")
  },
  {
    name: _91contract_93GlV318eHqYMeta?.name,
    path: "/contract/:contract()",
    meta: _91contract_93GlV318eHqYMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract].vue"),
    children: [
  {
    name: "contract-contract-balances",
    path: "balances",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/balances.vue")
  },
  {
    name: "contract-contract-cw20balances",
    path: "cw20balances",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/cw20balances.vue")
  },
  {
    name: "contract-contract",
    path: "",
    meta: indexZGpec3rzKQMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/index.vue")
  },
  {
    name: "contract-contract-init",
    path: "init",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/init.vue")
  },
  {
    name: query_45and_45executefHFV55VTJUMeta?.name,
    path: "query-and-execute",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/query-and-execute.vue"),
    children: [
  {
    name: "contract-contract-query-and-execute-execute",
    path: "execute",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/query-and-execute/execute.vue")
  },
  {
    name: "contract-contract-query-and-execute",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/query-and-execute/index.vue")
  }
]
  },
  {
    name: "contract-contract-token-holders",
    path: "token-holders",
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/token-holders.vue")
  }
]
  },
  {
    name: "env",
    path: "/env",
    meta: envfVxRgKM8eSMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/injective/injective-explorer/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancepyNbpbFYruMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/maintenance.vue")
  },
  {
    name: "market-market",
    path: "/market/:market()",
    component: () => import("/root/injective/injective-explorer/pages/market/[market].vue")
  },
  {
    name: marketsVfkNwOYM9KMeta?.name,
    path: "/markets",
    component: () => import("/root/injective/injective-explorer/pages/markets.vue"),
    children: [
  {
    name: "markets-funding-rate-history",
    path: "funding-rate-history",
    component: () => import("/root/injective/injective-explorer/pages/markets/funding-rate-history.vue")
  },
  {
    name: "markets",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/markets/index.vue")
  },
  {
    name: "markets-realtime-funding-rate",
    path: "realtime-funding-rate",
    component: () => import("/root/injective/injective-explorer/pages/markets/realtime-funding-rate.vue")
  },
  {
    name: "markets-trading-rules",
    path: "trading-rules",
    component: () => import("/root/injective/injective-explorer/pages/markets/trading-rules.vue")
  }
]
  },
  {
    name: "nft-id",
    path: "/nft/:id()",
    component: () => import("/root/injective/injective-explorer/pages/nft/[id]/index.vue")
  },
  {
    name: "raw-tx",
    path: "/raw-tx",
    component: () => import("/root/injective/injective-explorer/pages/raw-tx.vue")
  },
  {
    name: smart_45contractsStjYabRro6Meta?.name,
    path: "/smart-contracts",
    component: () => import("/root/injective/injective-explorer/pages/smart-contracts.vue"),
    children: [
  {
    name: "smart-contracts-code",
    path: "code",
    component: () => import("/root/injective/injective-explorer/pages/smart-contracts/code.vue")
  },
  {
    name: "smart-contracts",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/smart-contracts/index.vue")
  }
]
  },
  {
    name: "testing",
    path: "/testing",
    component: () => import("/root/injective/injective-explorer/pages/testing.vue")
  },
  {
    name: trade_45history4wdxWrri2GMeta?.name,
    path: "/trade-history",
    component: () => import("/root/injective/injective-explorer/pages/trade-history.vue"),
    children: [
  {
    name: "trade-history-derivatives-tradeId",
    path: "derivatives/:tradeId()",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/derivatives/[tradeId].vue")
  },
  {
    name: "trade-history-derivatives",
    path: "derivatives",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/derivatives/index.vue")
  },
  {
    name: "trade-history",
    path: "",
    meta: indexoVQXdDDGgUMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/trade-history/index.vue")
  },
  {
    name: "trade-history-spot-tradeId",
    path: "spot/:tradeId()",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/spot/[tradeId].vue")
  },
  {
    name: "trade-history-spot",
    path: "spot",
    component: () => import("/root/injective/injective-explorer/pages/trade-history/spot/index.vue")
  }
]
  },
  {
    name: _91transaction_93O7jI16oisQMeta?.name,
    path: "/transaction/:transaction()",
    meta: _91transaction_93O7jI16oisQMeta || {},
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction].vue"),
    children: [
  {
    name: "transaction-transaction-event-logs",
    path: "event-logs",
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction]/event-logs.vue")
  },
  {
    name: "transaction-transaction",
    path: "",
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction]/index.vue")
  }
]
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/root/injective/injective-explorer/pages/transactions.vue")
  }
]