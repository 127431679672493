import {
  TokenStatic,
  getEthereumAddress,
  getInjectiveAddress
} from '@injectivelabs/sdk-ts'
import { getTime, set } from 'date-fns'
import { sharedTokenClient } from '@shared/Service'
import { COMMUNITY_SPEND_POOL_ADDRESS } from './constant'
import { tokenFactoryStatic } from '@/app/Service'
import { DOMEvent, SpendPoolFilterSide } from '@/types'

export const isTransactionFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  return (
    (valueTrimmed.toLocaleLowerCase().startsWith('0x') &&
      valueTrimmed.trim().length === 66) ||
    (!valueTrimmed.trim().toLocaleLowerCase().startsWith('0x') &&
      valueTrimmed.length === 64)
  )
}

export const isAddressFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  if (
    valueTrimmed.toLowerCase().startsWith('inj') &&
    valueTrimmed.length === 42
  ) {
    try {
      return !!getEthereumAddress(valueTrimmed)
    } catch (error: any) {
      return false
    }
  }

  return false
}

export const isSubaccountFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  return (
    valueTrimmed.toLowerCase().startsWith('0x') &&
    valueTrimmed.length === 66 &&
    valueTrimmed
      .slice(42)
      .split('')
      .filter((char) => char === '0').length > 22
  )
}

export const isBlockFormat = (value: number | string): boolean => {
  const numericValue = Number(value || '')

  return Number.isInteger(numericValue) && numericValue > 0
}

export const isBridgedFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  return (
    valueTrimmed.toLowerCase().startsWith('peggy') && valueTrimmed.length === 47
  )
}

export const isIbcFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  return (
    valueTrimmed.toLowerCase().startsWith('ibc') && valueTrimmed.length === 68
  )
}

export const isNativeFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  if (valueTrimmed.toLocaleLowerCase().startsWith('factory')) {
    try {
      const [, , address] = valueTrimmed.split('/')

      return !!getEthereumAddress(address)
    } catch (error: any) {
      return false
    }
  }

  const isNativeInjDenom =
    valueTrimmed.toLocaleLowerCase() === 'inj' && valueTrimmed.length === 3

  const isInsuranceFundDenom = valueTrimmed.startsWith('share')

  return isNativeInjDenom || isInsuranceFundDenom
}

export const isWasmCodeFormat = (value: number | string): boolean => {
  const numericValue = Number(value)

  return Number.isInteger(numericValue) && numericValue > 0
}

export const isEthereumAddressFormat = (value: string): boolean => {
  const valueTrimmed = (value || '').trim()

  if (valueTrimmed.toLowerCase().startsWith('0x')) {
    try {
      return !!getInjectiveAddress(value)
    } catch (error: any) {
      return false
    }
  }

  return false
}

export const passInputValidation = (
  event: DOMEvent<HTMLInputElement>
): boolean => {
  if (!event.key) {
    return true
  }

  const pattern = /^[a-zA-Z0-9\-_():./\s]*$/

  return pattern.test(event.key)
}

export const roundToNearestSecond = (timestamp: number) => {
  return getTime(
    set(timestamp, {
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    })
  )
}

export const isInjectiveAddress = (value: string) => {
  try {
    return !!getEthereumAddress(value)
  } catch (error: any) {
    return false
  }
}

export const getCommunitySpentPoolSendersAndReceivers = (
  side?: SpendPoolFilterSide,
  address?: string
) => {
  if (!side) {
    return {
      address: address || undefined,
      senders: undefined,
      recipients: undefined
    }
  }

  if (side === SpendPoolFilterSide.From) {
    return {
      address: undefined,
      senders: COMMUNITY_SPEND_POOL_ADDRESS,
      recipients: address || undefined
    }
  }

  return {
    address: undefined,
    senders: address || undefined,
    recipients: COMMUNITY_SPEND_POOL_ADDRESS
  }
}

export const isInjDomainNameSearch = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = '\\.inj'

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isValidInjDomainName = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = /^[^\s]{3,}\.inj$/

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isInjBonfidaDomainNameSearch = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = '\\.sol'

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isValidInjBonfidaDomainName = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = /^[^\s]{3,}\.sol$/

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const getToken = async (
  denomOrSymbol: string
): Promise<TokenStatic | undefined> => {
  const token = tokenFactoryStatic.toToken(denomOrSymbol)

  if (token) {
    return token
  }

  const asyncToken = await sharedTokenClient.queryToken(denomOrSymbol)

  return asyncToken
}

export const resolveIpfsUrl = (url: string): string => {
  if (url.startsWith('ipfs://')) {
    return url.replace('ipfs://', 'https://ipfs.io/ipfs/')
  }
  return url
}

export const stripHtml = (html: string): string => {
  return html.replace(/<[^>]+>/g, '')
}
