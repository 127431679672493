export default {
  nft: {
    traits: 'Traits',
    creator: 'Creator',
    tokenId: 'Token Id',
    contract: 'Contract',
    noNfts: 'No NFTs found',
    notFound: 'NFT not found',
    description: 'Description'
  }
}
